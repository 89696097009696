import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { GetPolicyTuningHpaCurrentDataResponse } from "../../../api/fetcher";
import Tooltip from "../../../components/Tooltip";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";

enum Reasons {
  KeepOrigin = "Keep original",
  Percentile = "Percentile",
  Max = "Max",
  OptionalBoundaryAddition = "Optional boundary addition",
}

interface Props {
  data: GetPolicyTuningHpaCurrentDataResponse | undefined;
}

const ReplicasBadges = ({ data }: Props) => {
  const [reason, setReason] = useState<Reasons | null>(null);
  const [description, setDescription] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (data) {
      let newDescription: React.ReactNode;
      switch (true) {
        case !!data.keepMinReplicas:
          setReason(Reasons.KeepOrigin);
          newDescription = (
            <>
              The min replicas recommendation is based on <b>original HPA configuration</b>.
            </>
          );
          break;
        case !data.keepMinReplicas && data.percentile && data.percentile > 0 && data.percentile < 100:
          setReason(Reasons.Percentile);
          newDescription = (
            <>
              The min replicas recommendation is based on the <b>{data.percentile}th percentile of replicas observed</b>{" "}
              in the last 14 days.
            </>
          );
          break;
        case !data.keepMinReplicas && data.percentile && data.percentile === 100:
          setReason(Reasons.Max);
          newDescription = (
            <>
              Min replica recommendation is based on the <b>maximum replica</b> observed in the last 14 days.
            </>
          );
          break;
        default:
          break;
      }

      const doesAnyOfTheCappingStatusesIsSetToMin = data?.cappingStatuses?.some((status) => status.type === "min");
      setDescription(
        <>
          {newDescription}{" "}
          {doesAnyOfTheCappingStatusesIsSetToMin && (
            <div className="mt-3 text-[10px] italic">
              <b>Note</b>: the actual min replicas value is capped by the <b>min replicas boundary</b>.
            </div>
          )}
        </>
      );
    }
  }, [data]);

  if (!reason) {
    return null;
  }

  return (
    <Typography
      variant="caption"
      className="flex items-center justify-center px-4 gap-1 w-full max-w-[400px] truncate border border-border round-lg min-h-[30px]"
    >
      <b>Min replica method:</b>{" "}
      <span className="flex items-center gap-1">
        {reason}
        {description && (
          <Tooltip title={description} maxWidth={500}>
            <CreativeInfoIcon width={12} height={12} />
          </Tooltip>
        )}
      </span>
    </Typography>
  );
};

export default ReplicasBadges;
