import { SCALEOPS_COLORS } from "../../../../colors";

export enum EventType {
  OptimizationBlocked = "optimizationBlocked",
  PodEviction = "podEviction",
  PodOptimized = "podOptimized",
}

export const EventYPosition: Record<EventType, number> = {
  [EventType.OptimizationBlocked]: 1,
  [EventType.PodEviction]: 2,
  [EventType.PodOptimized]: 3,
};

export const EventColor: Record<EventType, string> = {
  [EventType.OptimizationBlocked]: SCALEOPS_COLORS.primary.purpleBlue,
  [EventType.PodEviction]: SCALEOPS_COLORS.events.eviction,
  [EventType.PodOptimized]: SCALEOPS_COLORS.events.throttling,
};

export const EventDisplayName: Record<EventType, string> = {
  [EventType.OptimizationBlocked]: "Optimization Blocked",
  [EventType.PodEviction]: "Pod Eviction",
  [EventType.PodOptimized]: "Pod Optimized",
};

export type EventChartPoint = {
  timestamp: number;
  position: number;
  color: string;
  count: number;
  message?: string;
  cpu?: number;
  memory?: number;
};
