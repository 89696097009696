import FormInput from "../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { FeatureEnabled } from "../../../../utils/FeaturesHelper";
import useIsReadyOnlyFrontEnd from "../../../../utils/useIsReadyOnlyFrontEnd";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const GeneralMaxReplicas = ({ isCustomizedPolicy }: Props) => {
  const isDemoVersion = FeatureEnabled("DemoVersion");
  const isReadOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  if (!isDemoVersion) return null;

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription title="Max replicas" description="Set the maximum replicas for this workload." />
      <div className="flex flex-col gap-4">
        <FormInput
          label="Max replicas"
          name="generalMaxReplicas"
          type="number"
          disableChange={!isCustomizedPolicy || isReadOnlyFrontEnd}
        />
      </div>
    </div>
  );
};

export default GeneralMaxReplicas;
