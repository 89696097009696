import dayjs from "dayjs";
import { DEFAULT_POLICY_NAME } from "../pages/HPAPolicies/utils";
import { components } from "./schema";

export type GetPolicyTuningDiagnosticsResponse = components["schemas"]["PolicyTuningGetDiagnosticDataResponse"];

/**
 * Cache for the mocked data
 */
const policies: components["schemas"]["Hpa_policiesHpaListResponse"] = {};
const freeTrial: components["schemas"]["VersionFreeTrial"] = {
  daysLeft: 16,
  hasAskedForPremium: false,
  isBlocked: false,
  premiumUpgradeFailed: true,
};
let newSchedulePolicesMock: components["schemas"]["V1alpha1Policy"][];
let mockedWorkloadEventsGraphResponse: {
  from?: number | null | undefined;
  to?: number | null | undefined;
  data?: components["schemas"]["WorkloadsEventGraph"];
} = {};

/**
 * update freeTrialMockData
 */

export const updateFreeTrialMockData = (data: components["schemas"]["VersionFreeTrial"]) => {
  Object.assign(freeTrial, data);
};

/**
 * getMocked freeTrial data
 */
export const getMockedFreeTrialData = (): components["schemas"]["VersionFreeTrial"] => {
  return freeTrial;
};

/**
 * Common utils
 */
interface GetRandomIntFromRange {
  min: number;
  max: number;
}

const getRandomBoolean = (threshold = 0.5): boolean => {
  return Math.random() < threshold;
};
const getRandomIntFromRange = ({ min, max }: GetRandomIntFromRange): number => {
  return Math.floor(Math.random() * (max - min) + min);
};

const getRandomFloatFromRange = ({ min, max }: GetRandomIntFromRange): number => {
  return Math.random() * (max - min) + min;
};

const getRandomTrueOrFalse = (): boolean => {
  return Math.random() < 0.5;
};

const getRandomNumberFromOptions = (options: string[]) => {
  return options[Math.floor(Math.random() * options.length)];
};

const delay = async () => {
  await new Promise((resolve) => setTimeout(resolve, 3000));
};

const getRandomHours = (roundedHour?: boolean) => {
  const hours = getRandomIntFromRange({ min: 0, max: 23 });
  const minutes = roundedHour ? "00" : getRandomIntFromRange({ min: 0, max: 59 });
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
};

/**
 * Get mocked workload events graph response
 */
const NUMBER_OF_MOCKED_EVENTS_ENTRIES = 100;
const PROBABILITY_FOR_EVENT_TO_OCCUR = 0.002;

export const getMockedWorkloadEventsGraphResponse = (
  from: number | null | undefined,
  to: number | null | undefined
): components["schemas"]["WorkloadsEventGraph"] => {
  if (mockedWorkloadEventsGraphResponse?.from === from && mockedWorkloadEventsGraphResponse?.to === to) {
    return mockedWorkloadEventsGraphResponse.data ?? { eventGraphPoints: [] };
  }

  if (!from || !to)
    return {
      eventGraphPoints: [],
    };

  let interval = Math.round((to - from) / NUMBER_OF_MOCKED_EVENTS_ENTRIES);
  if (interval < 1) {
    interval = 1;
  }

  const timestamps = Array.from({ length: NUMBER_OF_MOCKED_EVENTS_ENTRIES }, (_, i) => {
    return dayjs(from + interval * i).format("YYYY-MM-DDTHH:mm:ss");
  });

  const points: components["schemas"]["WorkloadsEventGraphPoint"][] = timestamps.map((timestamp) => {
    const optimizationBlocked = getRandomBoolean(PROBABILITY_FOR_EVENT_TO_OCCUR)
      ? {
          optimizationBlocked: {
            count: getRandomIntFromRange({ min: 1, max: 10 }),
            message: "An example blocked opt message",
          },
        }
      : undefined;

    const podEviction = getRandomBoolean(PROBABILITY_FOR_EVENT_TO_OCCUR)
      ? {
          podEviction: {
            count: getRandomIntFromRange({ min: 1, max: 10 }),
            message: "An example count message",
          },
        }
      : undefined;

    const podOptimized = getRandomBoolean(PROBABILITY_FOR_EVENT_TO_OCCUR)
      ? {
          podOptimized: {
            count: getRandomIntFromRange({ min: 1, max: 10 }),
            cpu: getRandomFloatFromRange({ min: 0, max: 2 }),
            memory: getRandomIntFromRange({ min: 10000, max: 1000000 }),
          },
        }
      : undefined;

    return {
      timestamp,
      eventDetails: [optimizationBlocked, podEviction, podOptimized].filter((entity) => entity) as {
        optimizationBlocked?: { count?: number; message?: string };
        podEviction?: { count?: number; message?: string };
        podOptimized?: { count?: number; cpu?: number; memory?: number };
      }[],
    };
  });

  mockedWorkloadEventsGraphResponse = {
    from,
    to,
    data: {
      eventGraphPoints: points,
    },
  };

  return mockedWorkloadEventsGraphResponse.data ?? { eventGraphPoints: [] };
};

/**
 * Get mocked policySchedule Array
 */

export const getMockedPolicySchedule = (arrayLength = 10): components["schemas"]["V1alpha1Policy"][] => {
  if (newSchedulePolicesMock) {
    return newSchedulePolicesMock;
  }

  const initialState: components["schemas"]["V1alpha1Policy"][] = Array.from({ length: arrayLength }, (_, i) => {
    const name = `mock-schedule-policy-${i}`;
    const type = "Schedule";
    const defaultPolicy = "production";
    const sleepMode = false;
    const useRelativeHistoryData = getRandomTrueOrFalse();
    const periods = Array.from({ length: 3 }, () => {
      return {
        weeklyConfig: {
          beginTime: getRandomHours(true),
          endTime: getRandomHours(true),
          days: Array.from({ length: getRandomIntFromRange({ min: 1, max: 7 }) }, (_, i) => i),
        },
      };
    });

    return {
      metadata: {
        name,
      },
      spec: {
        type,
        policySchedule: {
          schedulePolicyConfig: {
            defaultPolicy,
            rules: [
              {
                periods,
                policyName: "cost",
                sleepMode,
                useRelativeHistoryData,
              },
            ],
          },
        },
      },
    };
  });

  newSchedulePolicesMock = initialState;
  return newSchedulePolicesMock;
};

/**
 * Get mocked policy tuning diagnostics
 */
type GetNotEvictableGraphsResponse = components["schemas"]["NodeGroupsGetNotEvictableGraphsResponse"];

export const getMockGetNotEvictableGraphsResponse = (
  from?: number | null,
  to?: number | null,
  propertyNames?: string[]
): GetNotEvictableGraphsResponse => {
  const numberOfEntries = 100;
  const minEntryValue = 0;
  const maxEntryValue = 253;

  const propertyNamesArr = propertyNames ?? ["example"];
  const gapBetweenDates = from && to ? Math.round((to - from) / numberOfEntries) : 1000 * 60;
  const start = Number(from);
  const timestamps = Array.from({ length: numberOfEntries }, (_, i) => {
    return start + gapBetweenDates * i;
  });

  const data = timestamps.map((timestamp) => {
    return {
      timestamp: dayjs(timestamp).format("YYYY-MM-DDTHH:mm:ss"),
      values: propertyNamesArr.reduce((acc, propertyName) => {
        acc[propertyName] = getRandomIntFromRange({ min: minEntryValue, max: maxEntryValue });
        return acc;
      }, {} as Record<string, number>),
    };
  });

  return { values: data } as GetNotEvictableGraphsResponse;
};

export const mockedUnevictableTableAndTopData = {
  unevictableWorkloads: {
    savingAvailable: 1,
    optimizedPods: 1,
    totalPods: 4,
    isAutomated: true,
  },
  ownerlessWorkloads: {
    savingAvailable: 4,
    optimizedPods: 6,
    totalPods: 7,
    isAutomated: false,
  },
  unhealthyWorkloads: {
    savingAvailable: 9,
    optimizedPods: 10,
    totalPods: 80,
    isAutomated: false,
  },
  kubeSystemWorkloads: {
    savingAvailable: 99,
    optimizedPods: 8,
    totalPods: 8,
    isAutomated: true,
  },
  cost: 18,
  blockedNodes: 770,
  wastedSpend: 8,
  optimizedPods: 5,
  totalPods: 25,
};

/**
 * GetHPAPolicies Mock
 */
const NUMBER_OF_POLICIES = 10;
const BUILT_IN_POLICY_LABELS = { "scaleops.sh/builtin-policy": "true" };

export const getHPAPoliciesMockData = (): components["schemas"]["Hpa_policiesHpaListResponse"] => {
  if (Number(policies.policies?.length) > 0) {
    return policies;
  }

  policies.policies = Array.from({ length: NUMBER_OF_POLICIES }, (_, i) => {
    const policyName = i ? `policy-${i}` : DEFAULT_POLICY_NAME;
    const enableOptimization = getRandomTrueOrFalse();
    const predictableWorkloadsPercentilePercentage = getRandomIntFromRange({ min: 0, max: 100 });
    const lookAheadDuration = getRandomNumberFromOptions(["10m", "20m", "30m", "1h"]);
    const generalWorkloadsPercentilePercentage = Number(getRandomIntFromRange({ min: 0, max: 100 }));
    const generalWorkloadsWindow = String(getRandomNumberFromOptions(["168h", "336h", "504h"]));
    const requiredWindowCoverageDuration = getRandomNumberFromOptions(["48h", "96h", "168h"]);
    const minReplicasMinAllowed = Number(getRandomIntFromRange({ min: 0, max: 100 }));
    const isBuiltIn = getRandomTrueOrFalse();
    const metaDataLabels = !i || isBuiltIn ? BUILT_IN_POLICY_LABELS : undefined;

    return {
      metadata: {
        name: policyName,
        labels: metaDataLabels,
      },
      spec: {
        policyOptimize: {
          minReplicas: {
            predictableWorkloads: {
              enabled: enableOptimization,
              percentilePercentage: predictableWorkloadsPercentilePercentage,
            },
            generalWorkloads: {
              percentilePercentage: generalWorkloadsPercentilePercentage,
              window: generalWorkloadsWindow,
            },
            minAllowed: minReplicasMinAllowed,
          },
          replicas: {
            prediction: {
              lookAheadDuration,
            },
          },
        },
        updatePolicy: {
          requiredWindowCoverageDuration,
        },
      },
    };
  });

  return { policies: policies.policies };
};

/**
 * DeleteHPAPolicy Mock
 */
export const deleteHPAPolicyMockData = async ({ name }: { name: string }) => {
  await delay();
  policies.policies = policies.policies?.filter((policy) => policy.metadata?.name && policy.metadata.name !== name);
  return {};
};

/**
 * CreateHPAPolicy Mock
 */
export const createHPAPolicyMockData = async (params: components["schemas"]["HpaCreateInput"]) => {
  await delay();
  if (policies.policies && params.policy) {
    policies.policies.push(params.policy);
  }
  return {};
};
