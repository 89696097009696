import { GridColDef } from "@mui/x-data-grid";
import clsx from "clsx";
import * as React from "react";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { components } from "../../api/schema";
import CustomTooltip from "../Tooltip";
import { ViewPeriodOptions } from "./NodeOverview/SelectViewPeriod";

const GRAY_BG_CLASS_NAME = "bg-border px-1 break-all";

const NodeGroupMonthlyCost = (group: components["schemas"]["NodeGroupsNodeGroupConfiguration"], forceSpot: boolean) => {
  return group.minNodes * 24 * 30 * (group.spot || forceSpot ? group.spotCost : group.cost);
};

const formatXDigits = (num: number, digits = 1) => {
  return ((num * 100) / 100).toFixed(digits);
};

const SimpleEllipsisWithTooltip = ({ text, onClick }: { text: string; onClick?: () => void }) => {
  return (
    <div
      className={clsx("max-w-full", {
        "cursor-pointer": !!onClick,
      })}
      onClick={onClick}
    >
      <CustomTooltip title={text}>
        <p className="truncate">{text}</p>
      </CustomTooltip>
    </div>
  );
};

export { formatXDigits, NodeGroupMonthlyCost, SimpleEllipsisWithTooltip };

export enum BlockingOwner {
  Node = "Node",
  Pod = "Pod",
}

export enum NodeReasons {
  ScaleDownDisabledAnnotation = "ScaleDownDisabledAnnotation",
  NodeGroupMinSizeReached = "NodeGroupMinSizeReached",
  MandatoryNodeLabelsDoesNotExist = "MandatoryNodeLabelsDoesNotExist",
  NodePendingDeletion = "NodePendingDeletion",
  UninitializedNode = "UninitializedNode",
  DoNotConsolidateAnnotationOnNode = "DoNotConsolidateAnnotationOnNode",
  ProvisionerDoesNotExists = "ProvisionerDoesNotExists",
  DoNotConsolidate = "DoNotConsolidate",
  NotEmptyForEnoughTime = "NotEmptyForEnoughTime",
  ScalingDownAllocationThresholdIsTooLow = "ScalingDownAllocationThresholdIsTooLow",
  NodePoolUnderutilizedPolicyWithNeverConsolidateAfter = "NodePoolUnderutilizedPolicyWithNeverConsolidateAfter",
  NodePoolBudgetExceeded = "NodePoolBudgetExceeded",
  NodeNotSafeToEvictAnnotation = "NodeNotSafeToEvictAnnotation",
  NodeNotSafeToEvictLabel = "NodeNotSafeToEvictLabel",
  HighMemoryUtilization = "HighMemoryUtilization",
  HighCpuUtilization = "HighCpuUtilization",
  HighCpuAndMemoryUtilization = "HighCpuAndMemoryUtilization",
}

export const nodeReasonsMessages: Record<
  NodeReasons,
  {
    jsx: JSX.Element;
    renderFunction?: (
      nodeGroupName?: string | undefined | null,
      nodeGroups?: components["schemas"]["UtilsNodeGroupInfo"][],
      customMessage?: string | undefined | null
    ) => React.ReactNode;
  }
> = {
  [NodeReasons.ScaleDownDisabledAnnotation]: {
    jsx: (
      <>
        Node has an <b>annotation preventing scale down</b>
      </>
    ),
  },
  [NodeReasons.NodeGroupMinSizeReached]: {
    jsx: (
      <>
        Node group <b>reached its minimum configured size</b>
      </>
    ),
    renderFunction: (nodeGroup, nodeGroups) => {
      const nodeGroupData = nodeGroups?.find((ng) => ng.name === nodeGroup);
      const size = nodeGroupData?.minSize;

      if (!size) return <>{nodeGroup} has reached its minimum configured size.</>;
      return (
        <>
          <b>{nodeGroup}</b> has reached its minimum configured <b>size of {size} nodes</b>
        </>
      );
    },
  },
  [NodeReasons.MandatoryNodeLabelsDoesNotExist]: {
    jsx: (
      <>
        There are <b>missing labels</b> preventing node scale down
      </>
    ),
  },
  [NodeReasons.NodePendingDeletion]: {
    jsx: (
      <>
        The node is <b>marked for deletion</b>
      </>
    ),
  },
  [NodeReasons.UninitializedNode]: {
    jsx: (
      <>
        Node <b>was not initialized</b>
      </>
    ),
  },
  [NodeReasons.NodeNotSafeToEvictAnnotation]: {
    jsx: (
      <>
        Node has an <b>annotation preventing scale down</b>:
      </>
    ),
    renderFunction: (nodeGroup, nodeGroups, customMessage) => {
      return (
        <>
          Node has annotation: <p className={GRAY_BG_CLASS_NAME}>{customMessage}</p>{" "}
          <b>preventing node from scale down</b>
        </>
      );
    },
  },
  [NodeReasons.NodeNotSafeToEvictLabel]: {
    jsx: (
      <>
        Node has an <b>label preventing scale down</b>:
      </>
    ),
    renderFunction: (nodeGroup, nodeGroups, customMessage) => {
      return (
        <>
          Node has label: <p className={GRAY_BG_CLASS_NAME}>{customMessage}</p> <b>preventing node from scale down</b>
        </>
      );
    },
  },
  [NodeReasons.HighCpuAndMemoryUtilization]: {
    jsx: (
      <>
        The node's <b>high CPU and Memory allocation</b> is preventing it from scaling down
      </>
    ),
  },
  [NodeReasons.HighMemoryUtilization]: {
    jsx: (
      <>
        The node's <b>high Memory allocation</b> is preventing it from scaling down
      </>
    ),
  },
  [NodeReasons.HighCpuUtilization]: {
    jsx: (
      <>
        The node's <b>high CPU allocation</b> is preventing it from scaling down
      </>
    ),
  },
  [NodeReasons.DoNotConsolidateAnnotationOnNode]: {
    jsx: (
      <>
        <b>do-not-consolidate annotation</b> preventing scale down
      </>
    ),
  },
  [NodeReasons.ProvisionerDoesNotExists]: {
    jsx: (
      <>
        Provisioner <b>does not exists</b>
      </>
    ),
  },
  [NodeReasons.DoNotConsolidate]: {
    jsx: (
      <>
        <b>Consolidation is not enabled</b> in the node's provisioner
      </>
    ),
  },
  [NodeReasons.NodePoolUnderutilizedPolicyWithNeverConsolidateAfter]: {
    jsx: (
      <>
        The node pool is configured with <b>'consolidationPolicy'</b> set to <b>'WhenUnderutilized'</b> and{" "}
        <b>'consolidateAfter'</b> set to <b>'Never'</b>, which is preventing the node from scaling down.
      </>
    ),
  },
  [NodeReasons.NotEmptyForEnoughTime]: {
    jsx: (
      <>
        The node is <b>still not expired</b>
      </>
    ),
  },
  [NodeReasons.ScalingDownAllocationThresholdIsTooLow]: {
    jsx: <>The cluster autoscaler was configured with a low default utilization, blocking nodes from scaling down.</>,
  },
  [NodeReasons.NodePoolBudgetExceeded]: {
    jsx: (
      <>
        The node pool has <b>exceeded its budget</b>
      </>
    ),
    renderFunction: (_, __, customMessage) => {
      return (
        <>
          <b>Budget restriction:</b> {customMessage}.
        </>
      );
    },
  },
};

export enum NoPlaceToMovePodsReasons {
  TooManyPods = "Too many pods",
  Insufficient = "Insufficient",
  NodeDidNotMatchSchedulerEnforcedNodeAffinity = "node(s) didn't match scheduler-enforced node affinity",
  NodeDidNotMatchPodNodeAffinitySelector = "node(s) didn't match Pod's node affinity/selector",
  NodeHadUntoleratedTaint = "node(s) had untolerated taint",
  NodeHadNoAvailableVolumeZone = "node(s) had no available volume zone",
  NodeHadNoAvailableDisk = "node(s) had no available disk",
  NodeHasPodUsingPersistentVolumeClaimWithTheSameNameAndReadWriteOncePodAccessMode = "node has pod using PersistentVolumeClaim with the same name and ReadWriteOncePod access mode",
  NodeDidNotFindAvailablePersistentVolumesToBind = "node(s) didn't find available persistent volumes to bind",
  NodeHadVolumeNodeAffinityConflict = "node(s) had volume node affinity conflict",
  NodeDidNotHaveEnoughFreeStorage = "node(s) did not have enough free storage",
  NodeUnavailableDueToOneOrMorePVCsBoundToNonExistentPVs = "node(s) unavailable due to one or more pvc(s) bound to non-existent pv(s)",
  NodeDidNotMatchPodTopologySpreadConstraints = "node(s) didn't match pod topology spread constraints",
  NodeExceedMaxVolumeCount = "node(s) exceed max volume count",
  NodeDidNotSatisfyExistingPodsAntiAffinityRules = "node(s) didn't satisfy existing pods anti-affinity rules",
  NodeDidNotMatchPodAffinityRules = "node(s) didn't match pod affinity rules",
  NodeDidNotMatchPodAntiAffinityRules = "node(s) didn't match pod anti-affinity rules",
  PodAffinityTermsConflict = "pod affinity terms conflict",
  NodeDidNotHaveFreePortsForTheRequestedPodPorts = "node(s) didn't have free ports for the requested pod ports",
  NodeDidNotMatchTheRequestedNodeName = "node(s) didn't match the requested node name",
}

export const noPlaceToMovePodsReasonsMessages = {
  [NoPlaceToMovePodsReasons.TooManyPods]: {
    string: "Too many pods",
    jsx: (
      <>
        Other node(s) has reached <b>max number of pods</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.Insufficient]: {
    string: "Insufficient",
    jsx: (
      <>
        Other node(s) <b> insufficient</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotMatchSchedulerEnforcedNodeAffinity]: {
    string: "node(s) didn't match scheduler-enforced node affinity",
    jsx: (
      <>
        Other node(s) <b>didn't match scheduler-enforced node affinity</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotMatchPodNodeAffinitySelector]: {
    string: "node(s) didn't match Pod's node affinity/selector",
    jsx: (
      <>
        Other node(s) <b>didn't match Pod's node affinity/selector</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeHadUntoleratedTaint]: {
    string: "node(s) had untolerated taint",
    jsx: (
      <>
        Other node(s) <b>had untolerated taint</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeHadNoAvailableVolumeZone]: {
    string: "node(s) had no available volume zone",
    jsx: (
      <>
        Other node(s) <b>had no available volume zone</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeHadNoAvailableDisk]: {
    string: "node(s) had no available disk",
    jsx: (
      <>
        Other node(s) <b>had no available disk</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeHasPodUsingPersistentVolumeClaimWithTheSameNameAndReadWriteOncePodAccessMode]: {
    string: "node has pod using PersistentVolumeClaim with the same name and ReadWriteOncePod access mode",
    jsx: (
      <>
        Other node(s) has pod using <b>PersistentVolumeClaim</b> with the same name and <b>ReadWriteOncePod</b> access
        mode
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotFindAvailablePersistentVolumesToBind]: {
    string: "node(s) didn't find available persistent volumes to bind",
    jsx: (
      <>
        Other node(s) <b>didn't find available persistent volumes to bind</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeHadVolumeNodeAffinityConflict]: {
    string: "node(s) had volume node affinity conflict",
    jsx: (
      <>
        Other node(s) <b>had volume node affinity conflict</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotHaveEnoughFreeStorage]: {
    string: "node(s) did not have enough free storage",
    jsx: (
      <>
        Other node(s) <b>did not have enough free storage</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeUnavailableDueToOneOrMorePVCsBoundToNonExistentPVs]: {
    string: "node(s) unavailable due to one or more pvc(s) bound to non-existent pv(s)",
    jsx: (
      <>
        Other node(s) <b>unavailable due to one or more pvc(s) bound to non-existent pv(s)</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotMatchPodTopologySpreadConstraints]: {
    string: "node(s) didn't match pod topology spread constraints",
    jsx: (
      <>
        Other node(s) <b>didn't match pod topology spread constraints</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeExceedMaxVolumeCount]: {
    string: "node(s) exceed max volume count",
    jsx: (
      <>
        Other node(s) <b>exceed max volume count</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotSatisfyExistingPodsAntiAffinityRules]: {
    string: "node(s) didn't satisfy existing pods anti-affinity rules",
    jsx: (
      <>
        Other node(s) <b>didn't satisfy existing pods anti-affinity rules</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotMatchPodAffinityRules]: {
    string: "node(s) didn't match pod affinity rules",
    jsx: (
      <>
        Other node(s) <b>didn't match pod affinity rules</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotMatchPodAntiAffinityRules]: {
    string: "node(s) didn't match pod anti-affinity rules",
    jsx: (
      <>
        Other node(s) <b>didn't match pod anti-affinity rules</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.PodAffinityTermsConflict]: {
    string: "pod affinity terms conflict",
    jsx: (
      <>
        Other node(s) have pod <b>affinity terms conflict</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotHaveFreePortsForTheRequestedPodPorts]: {
    string: "node(s) didn't have free ports for the requested pod ports",
    jsx: (
      <>
        Other node(s) <b>didn't have free ports for the requested pod ports</b>
      </>
    ),
  },
  [NoPlaceToMovePodsReasons.NodeDidNotMatchTheRequestedNodeName]: {
    string: "node(s) didn't match the requested node name",
    jsx: (
      <>
        Other node(s) <b>didn't match the requested node name</b>
      </>
    ),
  },
};

export enum PodReasons {
  PodWithNoOwner = "PodWithNoOwner",
  MinReplicasReached = "MinReplicasReached",
  PodOwnerReachedToMinimumReplicas = "PodOwnerReachedToMinimumReplicas",
  LocalStorageRequested = "LocalStorageRequested",
  NotSafeToEvictAnnotation = "NotSafeToEvictAnnotation",
  NotSafeToEvictLabel = "NotSafeToEvictLabel",
  PdbPreventingScaleDown = "PdbPreventingScaleDown",
  PdbTemporaryBlockingScaleDown = "PdbTemporaryBlockingScaleDown",
  UnmovableKubeSystemPod = "UnmovableKubeSystemPod",
  NoPlaceToMovePods = "NoPlaceToMovePods",
}

export const podReasonsMessages = {
  [PodReasons.PodWithNoOwner]: (podName?: string) => (
    <>
      Pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p>owner <b>is not found</b>
    </>
  ),
  [PodReasons.MinReplicasReached]: (podName?: string) => (
    <>
      Pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p>has reached the <b>minimum replicas</b> as configured in the{" "}
      <b>cluster autoscaler</b>
    </>
  ),
  [PodReasons.PodOwnerReachedToMinimumReplicas]: (podName?: string) => (
    <>
      The pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p> has no owner and can't be recreated, preventing node scale
      down
    </>
  ),
  [PodReasons.LocalStorageRequested]: (podName?: string) => (
    <>
      Pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p> preventing scale down due to <b>local storage</b> (emptyDir or
      hostPath)
    </>
  ),
  [PodReasons.PdbPreventingScaleDown]: (podName?: string, reason?: string) => (
    <>
      <b>PDB Eviction Restriction</b> for pod: <p className="bg-border px-1">{podName}</p>
      {reason &&
        reason.split("--").map((line, index) => (
          <div key={index}>
            {line}
            <br />
          </div>
        ))}
    </>
  ),
  [PodReasons.PdbTemporaryBlockingScaleDown]: (podName?: string, reason?: string) => (
    <>
      <b>PDB Eviction Restriction</b> for pod: <p className="bg-border px-1">{podName}</p>
      {reason &&
        reason.split("--").map((line, index) => (
          <div key={index}>
            {line}
            <br />
          </div>
        ))}
    </>
  ),
  [PodReasons.NotSafeToEvictAnnotation]: (podName?: string, reason?: string) => (
    <>
      Pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p>
      {reason ? (
        reason.includes("--") ? (
          <>
            has annotation
            {reason.split("--").map((line, index) => (
              <div key={index}>
                <p className={index === 0 ? GRAY_BG_CLASS_NAME : ""}>{line}</p>
                {index === 0 && (
                  <div>
                    <b>that prevents node from scale down</b>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            has annotation that<b> prevents node from scale down</b> <br />
            <br />
          </>
        )
      ) : null}
    </>
  ),
  [PodReasons.NotSafeToEvictLabel]: (podName?: string, reason?: string) => (
    <>
      Pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p>
      {reason ? (
        reason.includes("--") ? (
          <>
            has label
            {reason.split("--").map((line, index) => (
              <div key={index}>
                <p className={index === 0 ? GRAY_BG_CLASS_NAME : ""}>{line}</p>
                {index === 0 && (
                  <div>
                    <b>that prevents node from scale down</b>
                  </div>
                )}
              </div>
            ))}
          </>
        ) : (
          <>
            has annotation that<b> prevents node from scale down</b> <br />
            <br />
          </>
        )
      ) : null}
    </>
  ),
  [PodReasons.UnmovableKubeSystemPod]: (podName?: string) => (
    <>
      kube-system pod <p className={GRAY_BG_CLASS_NAME}>{podName}</p> without PDB <b>preventing scale down</b>
    </>
  ),
  [PodReasons.NoPlaceToMovePods]: (podName?: string) => (
    // the default No Place To Move Pods reason
    <>
      <b>Blocking scale down pod</b> <p className={GRAY_BG_CLASS_NAME}>{podName}</p>{" "}
      <b>can’t move to other nodes due to</b>:
    </>
  ),
};

export const DIFF_DEFAULT_PROPS: Partial<GridColDef> = {
  flex: 1.3,
  minWidth: 180,
  type: "string",
  align: "center",
  disableColumnMenu: true,
  sortable: true,
};

export type NodeStatsResponseTypeSchema = components["schemas"]["UtilsNodeStats"];

export const NODE_ANALYTICS_SYNCH_ID = "NODE_ANALYTICS_SYNCH_ID";

export enum NodeOverviewTabs {
  Overview = "Overview",
  Analytics = "Troubleshoot",
  CaLogs = "CA Logs",
  Events = "Events",
  Yaml = "Yaml",
}

export const NODE_OVERVIEW_DATES_URL_PARAM = "nodeOverviewDates";
export const NODE_OVERVIEW_PERIOD_URL_PARAM = "nodeOverviewViewPeriod";
export const SELECTED_NODE_ID_QUERY_PARAM = "selectedNodeOverviewId";

export const useNodesViewPeriodQueryParams = () => {
  return useQueryParam(NODE_OVERVIEW_PERIOD_URL_PARAM, withDefault(StringParam, ViewPeriodOptions["1 day"]));
};

export const hasScaleDownWarning = (nodeRow?: NodeStatsResponseTypeSchema) =>
  !!nodeRow?.limitScaleDownSeverity && nodeRow.limitScaleDownSeverity.length > 0;
