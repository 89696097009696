import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import clsx from "clsx";
import * as React from "react";
import { memo, useEffect, useState } from "react";
import { ObjectParam, StringParam, useQueryParam, withDefault } from "use-query-params";
import {
  GetIsCaLogsSupported,
  GetIsCaLogsSupportedResponse,
  GetNodeGroupsNodes,
  GetNodeGroupsNodesResponse,
} from "../../../api/fetcher";
import Tab, { TABS_CONTAINER_CLASS_NAME } from "../../../components/Tab";
import { EventPoint } from "../../../pages/Overview/PolicyTuning/Diagnostics/utils";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";
import { IsSnapshotServer } from "../../../utils/FeaturesHelper";
import PropertiesChip from "../../../utils/PropertiesChip";
import Dialog from "../../Dialog";
import SnapshotWrapper from "../../SnapshotWrapper";
import { default as CustomTooltip, default as Tooltip } from "../../Tooltip";
import NotScalingDownTooltipContent from "../NotScalingDownTooltipContent";
import useGetNodeIconAndTitle from "../useGetNodeIconAndTitle";
import {
  hasScaleDownWarning,
  NODE_OVERVIEW_DATES_URL_PARAM,
  NodeOverviewTabs,
  NodeStatsResponseTypeSchema,
  SELECTED_NODE_ID_QUERY_PARAM,
  useNodesViewPeriodQueryParams,
} from "../Utils";
import Logs from "./Logs";
import NodeEvents from "./NodeEvents";
import NodesAnalytics from "./NodesAnalytics";
import NodeYaml from "./NodeYaml";
import PodsTable from "./PodsTable";
import ResourcesOverTimeCharts from "./ResourcesOverTimeCharts";
import { ViewPeriodOptions } from "./SelectViewPeriod";
import TaintsChip from "./TaintsChip";
import TopSection from "./TopSection";

interface Props {
  selectedNode: NodeStatsResponseTypeSchema | undefined;
  setSelectedNode: (node: NodeStatsResponseTypeSchema | undefined) => void;
  isOpen: boolean;
}

export const NODE_OVERVIEW_SELECTED_TAB_QUERY_KEY = "nodeOverviewSelectedTab";
const MIN_TAB_CONTENT_HEIGHT = 770;

const NodeOverviewContainer = memo(({ selectedNode, setSelectedNode, isOpen }: Props) => {
  const [, setEmptyEventArray] = useState<EventPoint[] | undefined>(undefined);
  const [from, setFrom] = useState<number>(adjustedDayjs().subtract(1, "day").unix());
  const [to, setTo] = useState<number>(adjustedDayjs().unix());
  const [selectedViewPeriod, setSelectedViewPeriod] = useNodesViewPeriodQueryParams();
  const [, setDates] = useQueryParam(NODE_OVERVIEW_DATES_URL_PARAM, ObjectParam);
  const [, setSelectedNodeOverviewId] = useQueryParam(SELECTED_NODE_ID_QUERY_PARAM, StringParam);
  const [pods, setPods] = useState<GetNodeGroupsNodesResponse["pods"] | undefined>(undefined);

  const [selectedTab, setSelectedTab] = useQueryParam(
    NODE_OVERVIEW_SELECTED_TAB_QUERY_KEY,
    withDefault(StringParam, NodeOverviewTabs.Overview)
  );

  const { queryFn, queryKey } = GetNodeGroupsNodes();
  const { data, isLoading, error, isFetching } = useQuery<GetNodeGroupsNodesResponse, Error>({
    queryKey: [queryKey, selectedNode?.name, from, to],
    queryFn: () => queryFn({ name: selectedNode?.name, from, to }),
    enabled: !!selectedNode?.name,
  });

  const { queryFn: isCaLogsSupportedQueryFn, queryKey: isSupportedScalerQueryKey } = GetIsCaLogsSupported();
  const { data: isCaLogsSupportedData } = useQuery<GetIsCaLogsSupportedResponse, Error>({
    queryKey: [isSupportedScalerQueryKey, selectedNode?.name],
    queryFn: () => isCaLogsSupportedQueryFn(selectedNode?.name ?? ""),
    enabled: IsSnapshotServer() && !!selectedNode?.name,
  });

  useEffect(() => {
    setFrom(adjustedDayjs().subtract(Number(selectedViewPeriod), "hour").unix());
    setTo(adjustedDayjs().unix());
    if (!selectedViewPeriod) {
      setSelectedViewPeriod(ViewPeriodOptions["1 day"]);
    }
  }, [selectedViewPeriod]);

  useEffect(() => {
    if (data && data.pods) {
      /* NOTE: sort by severity then by cpu request */
      const pods = data.pods?.sort((a, b) => {
        if (a.cpuRequest > b.cpuRequest) return -1;
        if (a.cpuRequest < b.cpuRequest) return 1;
        return 0;
      });

      pods.sort((a, b) => {
        if (a.blockingSeverity && b.blockingSeverity) {
          if (a.blockingSeverity === "warn" && b.blockingSeverity === "info") {
            return -1;
          }
          if (a.blockingSeverity === "info" && b.blockingSeverity === "warn") {
            return 1;
          }
          return 0;
        }
        if (a.blockingSeverity) {
          return -1;
        }
        if (b.blockingSeverity) {
          return 1;
        }
        return 0;
      });
      setPods(pods);
    }
  }, [data]);

  const annotations = data?.nodeInfo?.annotations ?? [];
  const labels = data?.nodeInfo?.labels ?? [];
  const taints = data?.nodeInfo?.taints ?? [];
  const tags = data?.nodeInfo?.tags;
  const blockedByScaleDown = hasScaleDownWarning(selectedNode);
  const { icon, title } = useGetNodeIconAndTitle(selectedNode?.limitScaleDownSeverity);
  const blockedByScaleDownValue = blockedByScaleDown && (
    <CustomTooltip
      maxWidth={500}
      title={
        <NotScalingDownTooltipContent
          nodeGroup={selectedNode?.nodeGroup ?? ""}
          limitScaleDownMessage={selectedNode?.limitScaleDownMessage ?? ""}
          limitScaleDownReason={selectedNode?.limitScaleDownReason}
          limitScaleDownAction={selectedNode?.limitScaleDownAction}
          blockingOwner={selectedNode?.blockingOwner}
          blockingName={selectedNode?.blockingName}
          blockingMessage={selectedNode?.blockingMessage}
          nodeReasonDetails={selectedNode?.nodeReasonDetails}
          nodeGroups={undefined}
          title={title}
          icon={icon}
        />
      }
    >
      {icon}
    </CustomTooltip>
  );

  const DialogTitleAndChips = (
    <div className="flex items-center gap-2">
      <Typography fontWeight={500}>Node overview</Typography>
      <ArrowRightAltIcon />
      <span>{selectedNode?.name}</span>
      <PropertiesChip topic="Node" title="Annotations" items={annotations} isLoading={isLoading} />
      <PropertiesChip topic="Node" title="Labels" items={labels} isLoading={isLoading} />
      <TaintsChip topic="Node" title="Taints" items={taints ?? []} isLoading={isLoading} />
    </div>
  );

  const handleClose = () => {
    setSelectedNode(undefined);
    setDates(undefined);
    setSelectedTab(undefined);
    setSelectedNodeOverviewId(undefined);
    setPods(undefined);
  };
  return (
    <Dialog
      isOpen={isOpen && !!selectedNode}
      onClose={handleClose}
      title={DialogTitleAndChips}
      dialogContentStyle={{
        padding: "0px",
      }}
      minWidth="1200px"
    >
      <div className="flex flex-col gap-[10px] pt-[10px] bg-background-chipHover">
        <div className="mt-[6px] pt-[30px]">
          <div className="bg-white px-[20px] py-[30px] relative">
            <div className={clsx(TABS_CONTAINER_CLASS_NAME, "w-full top-[-36px] left-0")}>
              {Object.entries(NodeOverviewTabs).map(([key, value]) => {
                return (
                  <Tooltip
                    maxWidth={500}
                    disabled={value !== NodeOverviewTabs.CaLogs || isCaLogsSupportedData?.isSupported}
                    title={
                      value === NodeOverviewTabs.CaLogs && !isCaLogsSupportedData?.isSupported
                        ? `CA logs are not supported for ${isCaLogsSupportedData?.caName ?? ""}`
                        : ""
                    }
                  >
                    <SnapshotWrapper
                      wrappedType="tab"
                      inactive={value !== NodeOverviewTabs.CaLogs && value !== NodeOverviewTabs.Events}
                    >
                      <Tab
                        key={key}
                        isSelected={selectedTab === value}
                        onClick={() => {
                          setSelectedTab(value);
                        }}
                        name={value}
                        dataTestId={`node-overview-${key}-tab`}
                        hasSelectedUnderline
                        disabled={value === NodeOverviewTabs.CaLogs && !isCaLogsSupportedData?.isSupported}
                      />
                    </SnapshotWrapper>
                  </Tooltip>
                );
              })}
            </div>
            {selectedTab === NodeOverviewTabs.Overview && (
              <>
                <div className="flex flex-col justify-center items-center min-w-[600px] min-h-[400px] gap-4 mb-5">
                  <TopSection
                    selectedViewPeriod={selectedViewPeriod}
                    setSelectedViewPeriod={setSelectedViewPeriod}
                    tags={tags?.filter((tag) => tag !== "unschedulable")}
                    status={data?.nodeInfo?.status}
                    schedulable={tags?.includes("unschedulable") ? false : true}
                    blocked={blockedByScaleDownValue}
                    blockedByTitle={title}
                    cost={selectedNode?.cost ?? 0}
                    creationTime={data?.nodeInfo?.creationTime}
                  />
                  <ResourcesOverTimeCharts
                    from={from}
                    to={to}
                    setEmptyEventArray={setEmptyEventArray}
                    selectedViewPeriod={selectedViewPeriod}
                    data={data}
                    isLoading={isLoading}
                    error={error}
                    isFetching={isFetching}
                  />
                  {/* DIAGNOSTICS removed due to RD-2946
                     <div className="w-full">
                       <NodeDiagnosticsContainer
                         name={selectedNodeName}
                         endDate={to}
                         startDate={from}
                         selectedViewPeriod={selectedViewPeriod}
                         emptyEventArray={emptyEventArray}
                       />
                     </div>
                   )} */}
                </div>
                <PodsTable rows={pods} isLoading={isLoading} />
              </>
            )}

            {selectedTab === NodeOverviewTabs.Analytics && (
              <div style={{ minHeight: MIN_TAB_CONTENT_HEIGHT }}>
                <NodesAnalytics
                  selectedNode={selectedNode}
                  nodesData={data}
                  isLoading={isLoading}
                  error={error}
                  isFetching={isFetching}
                  setEmptyEventArray={setEmptyEventArray}
                />
              </div>
            )}
            {selectedTab === NodeOverviewTabs.CaLogs && (
              <SnapshotWrapper noDesign>
                <Logs selectedNodeName={selectedNode?.name} minHeight={MIN_TAB_CONTENT_HEIGHT - 28} />
              </SnapshotWrapper>
            )}
            {selectedTab === NodeOverviewTabs.Events && (
              <SnapshotWrapper noDesign>
                <NodeEvents nodeName={selectedNode?.name} minHeight={MIN_TAB_CONTENT_HEIGHT - 28} />
              </SnapshotWrapper>
            )}
            {selectedTab === NodeOverviewTabs.Yaml && (
              <NodeYaml nodeName={selectedNode?.name} minHeight={MIN_TAB_CONTENT_HEIGHT - 28} />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
});

export default NodeOverviewContainer;
