import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ObjectParam, useQueryParam } from "use-query-params";
import { GetWorkloadEventsGraph, GetWorkloadEventsGraphResponse } from "../../../../api/fetcher";
import EventsIcon from "../../../../Icons/EventsIcon";
import SelectViewPeriod from "../SelectViewPeriod";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import { EventChartPoint, EventColor, EventType, EventYPosition } from "./utils";

const NOW = dayjs().unix() * 1000;
const { queryKey, queryFn } = GetWorkloadEventsGraph();

interface Props {
  kind: string;
  name: string;
  namespace: string;
}

const WorkloadHistoryEventsContainer = ({ kind, name, namespace }: Props) => {
  const [chartData, setChartData] = useState<EventChartPoint[]>([]);
  const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod] = useViewPeriodQueryParams();

  const { data } = useQuery<GetWorkloadEventsGraphResponse, Error>({
    queryKey: [queryKey, kind, name, namespace, urlDates?.from, urlDates?.to, selectedViewPeriod],
    queryFn: () =>
      queryFn({
        kind,
        name,
        namespace,
        from:
          dayjs(
            urlDates?.from ? Number(urlDates.from) * 1000 : dayjs(NOW).subtract(Number(selectedViewPeriod ?? 1), "hour")
          ).unix() * 1000,
        to: dayjs(urlDates?.to ? Number(urlDates.to) * 1000 : NOW).unix() * 1000,
      }),
  });

  useEffect(() => {
    if (data) {
      if (data.eventGraphPoints && data.eventGraphPoints.length > 0) {
        let newChartData: EventChartPoint[] = [];
        data.eventGraphPoints.forEach((point) => {
          const timestamp = dayjs(point.timestamp).unix();
          if (!point.timestamp) return null;
          if (!point.eventDetails) return { timestamp };

          point.eventDetails.forEach((event) => {
            Object.values(EventType).forEach((eventType) => {
              const eventData = {
                timestamp,
                position: EventYPosition[eventType],
                color: EventColor[eventType],
                count: event[eventType]?.count ?? 0,
                // eslint-disable-next-line no-console
                // @ts-expect-error known issue
                message: event[eventType]?.message as string,
                // eslint-disable-next-line no-console
                // @ts-expect-error known issue
                cpu: event[eventType]?.cpu as number,
                // eslint-disable-next-line no-console
                // @ts-expect-error known issue
                memory: event[eventType]?.memory as number,
              };

              newChartData = [...newChartData, eventData];
            });
          });
        });

        setChartData(newChartData);
      }
    }
  }, [data]);

  return (
    <div className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <div className="flex gap-10 w-full">
          <EventsIcon width={40} height={40} />
          <Typography variant="body2">
            <b>History Events</b>
            <p>Explore your workload events</p>
          </Typography>
        </div>
        <div className="h-16 w-[1px] bg-border" />
        <SelectViewPeriod />
      </div>
      <div>{JSON.stringify(chartData)}</div>
    </div>
  );
};

export default WorkloadHistoryEventsContainer;
